import React from 'react';
import {
  ChevronLeft,
  UserFilled,
  Menu,
  X,
  ChevronRight,
  Gear,
  LogOut,
  Play,
  PlayCircle,
  Calendar,
  Clock,
  Location,
  DollarSign,
  Check,
  Plus,
  CheckCircle,
  Eye,
  EyeOff,
  Heart,
  Comment,
  Image,
  ArrowRight,
  MoreHorizontal,
  Edit,
  Trash,
  RedStar,
  OrangeStar,
  LightRedStar,
  YellowStar,
  PostIcon,
  SendIcon,
  Star,
} from '../../components/Icons';
import { IconProps } from '../../components/Icons/Svg';

export enum IconNames {
  yellowStar = 'yellow-star',
  lightRedStar = 'light-red-star',
  orangeStar = 'orange-star',
  redStar = 'red-star',
  chevronLeft = 'chevron-left',
  chevronRight = 'chevron-right',
  user = 'user',
  menu = 'menu',
  x = 'x',
  gear = 'gear',
  logOut = 'log-out',
  play = 'play',
  playCircle = 'play-circle',
  calendar = 'calendar',
  clock = 'clock',
  location = 'location',
  dollarSign = 'dollar-sign',
  check = 'check',
  plus = 'plus',
  checkCircle = 'check-circle',
  eye = 'eye',
  eyeOff = 'eye-off',
  heart = 'heart',
  comment = 'comment',
  image = 'image',
  arrowRight = 'arrow-right',
  moreHorizontal = 'more-horizontal',
  edit = 'edit',
  trash = 'trash',
  postIcon = 'post-icon',
  sendIcon = 'send-icon',
  star = 'star',
}

type Icons = Record<IconNames, (props: IconProps) => JSX.Element>;

const icons: Icons = {
  star: Star,
  'post-icon': PostIcon,
  'send-icon': SendIcon,
  'yellow-star': YellowStar,
  'light-red-star': LightRedStar,
  'orange-star': OrangeStar,
  'red-star': RedStar,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  user: UserFilled,
  menu: Menu,
  x: X,
  gear: Gear,
  'log-out': LogOut,
  play: Play,
  'play-circle': PlayCircle,
  calendar: Calendar,
  clock: Clock,
  location: Location,
  'dollar-sign': DollarSign,
  'check-circle': CheckCircle,
  check: Check,
  plus: Plus,
  eye: Eye,
  'eye-off': EyeOff,
  heart: Heart,
  comment: Comment,
  image: Image,
  'arrow-right': ArrowRight,
  'more-horizontal': MoreHorizontal,
  edit: Edit,
  trash: Trash,
};

type Props = {
  id: keyof Icons;
} & IconProps;

const Icon = ({ id, ...iconProps }: Props) => {
  const Component = icons[id];

  if (!Component) {
    throw new Error(`No icon found for ID: ${id}`);
  }

  return <Component {...iconProps} />;
};

export default Icon;

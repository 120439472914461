import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { BASE_CLOUDINARY_URL } from '../../../services/cloudinary';
import { HeaderVariant } from '../Header';
import { buildImageUrl } from 'cloudinary-build-url';
import { Gravity, RESIZE_TYPES } from '@cld-apis/utils';

const STYLES = {
  light: {
    '--color': 'var(--color-gray-700)',
  },
  dark: {
    '--color': 'var(--color-white)',
  },
};

interface Props {
  variant: HeaderVariant;
  avatar: string | undefined;
  initials?: string;
}

/**
 * Component used for the avatar in navigation links
 * If avatar doesn't exist, default to showing a user's initials
 */
const NavigationAvatar = ({ avatar, variant, initials }: Props) => {
  const styles = STYLES[variant];
  const avatarUrl = avatar
    ? buildImageUrl(avatar, {
        transformations: {
          resize: {
            type: RESIZE_TYPES.THUMBNAIL,
            width: 100,
            height: 100,
          },
          gravity: Gravity.FaceCenter,
          radius: 'max',
        },
      })
    : undefined;

  return !!avatarUrl ? (
    <Wrapper style={styles}>
      <ImageFluid
        src={avatarUrl}
        width={100}
        height={100}
        // objectFit="contain"
        alt="Avatar picture"
      />
    </Wrapper>
  ) : (
    <InitialsWrapper style={styles}>
      <Initials>{initials}</Initials>
    </InitialsWrapper>
  );
};

const Wrapper = styled.div`
  width: 36px;
  height: 36px;
  border: 2px solid var(--color);
  background: var(--color);
  border-radius: 50%;
  overflow: hidden;
`;

const InitialsWrapper = styled(Wrapper)`
  background: var(--color-primary-300);
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding adding to counter bottom spacing from font */
  padding-top: 2px;
`;

const ImageFluid = styled(Image)`
  width: 100%;
  height: auto;
`;

const Initials = styled.p`
  font-family: 'Epilogue';
  font-size: var(--font-size-body-02);
  letter-spacing: var(--letter-spacing-sm);
  color: var(--color-white);
  text-transform: uppercase;
`;

export default NavigationAvatar;

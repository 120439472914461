import React, { useState } from 'react';
import styled from 'styled-components';
import Logo from './Components/Logo';
import NavigationMenu from './Components/NavigationMenu';
import UnstyledButton from '../UnstyledButton';
import Icon, { IconNames } from '../Icons/Icon';
import { useUserProfile } from '../../queries/user';
import useUserMetadata from '../../hooks/useUserMetadata';
import MobileMenu from './Components/MobileMenu';
import NavigationItem from './Components/NavigationItem';
import { UrlObject } from 'url';
import useAuthStore from 'hooks/useAuthStore';

declare type Url = string | UrlObject;

const STYLES = {
  dark: {
    '--bg-color': 'var(--color-black-transparent)',
  },
  light: {
    '--bg-color': 'var(--color-white-transparent)',
  },
};

export enum HeaderVariant {
  dark = 'dark',
  light = 'light',
}

export interface HeaderProps {
  variant: HeaderVariant;
}

interface WithLogoProps {
  withoutLogo?: never;
  href?: never;
  linkValue?: never;
}

interface WithoutLogoProps {
  withoutLogo: true;
  href: Url;
  linkValue: string;
}

type Props = HeaderProps & (WithLogoProps | WithoutLogoProps);

const Header = ({ variant, withoutLogo, href, linkValue }: Props) => {
  const styles = STYLES[variant];

  const { isSignedIn } = useAuthStore();
  const { data: profile } = useUserProfile();
  const { data: metadata } = useUserMetadata();

  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  return (
    <HeaderWrapper style={styles}>
      <MainHeader>
        {withoutLogo && href ? (
          <NavigationItem
            href={href}
            variant={variant}
            value={linkValue!}
            iconLeft
            id={IconNames.chevronLeft}
          />
        ) : (
          <Logo variant={variant} />
        )}
        <DesktopNav>
          <NavigationMenu
            variant={variant}
            signedIn={isSignedIn}
            subscriptions={metadata?.subscriptions}
            profile={profile}
          />
        </DesktopNav>
        <MobileNav>
          <UnstyledButton onClick={() => setShowMobileMenu(true)}>
            <Icon id={IconNames.menu} color="var(--color-white)" />
          </UnstyledButton>
        </MobileNav>
      </MainHeader>
      <MobileMenu
        isOpen={showMobileMenu}
        onDismiss={() => setShowMobileMenu(false)}
        signedIn={isSignedIn}
        subscriptions={metadata?.subscriptions}
        profile={profile}
      />
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  background: var(--bg-color);
`;

const MainHeader = styled.div`
  max-width: 1700px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px var(--spacing-64);
  min-height: 48px;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    padding: 6px 36px;
  }
  @media ${(p) => p.theme.queries.phoneAndDown} {
    padding: 6px 12px;
  }
`;

const DesktopNav = styled.nav`
  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: none;
  }
`;

const MobileNav = styled.nav`
  display: none;

  @media ${(p) => p.theme.queries.tabletAndDown} {
    display: flex;
    align-items: center;
  }
`;

export default Header;

import { api, Methods } from '../services/api';
import {
  RegisterRequest,
  SubscribeRequest,
  LiveWorkoutRegisterRequest,
  ValidatePromoCodeRequest,
  PromoCode,
  ValidateEmailRequest,
  ProgramRegisterRequest,
} from '@solin-fitness/types';

interface LoginResponse {
  [key: string]: any;
  data: {
    userId: number;
    userType: number;
  };
  pageUrl?: string;
}

export const fetchLogin = async (
  email: string,
  password: string,
): Promise<LoginResponse> =>
  await api<LoginResponse>(Methods.post, '/auth/login', {
    email,
    password,
  });

export const fetchRegister = async (data: RegisterRequest) =>
  await api(Methods.post, '/auth/register', data);

export const fetchSubscribe = async (data: SubscribeRequest, xPage: string) =>
  await api(Methods.post, '/auth/subscribe', data, {
    xPage,
  });

export const programRegister = async (
  data: ProgramRegisterRequest,
  programId: number,
  xPage: string,
) =>
  await api(Methods.post, `/auth/register/program/${programId}`, data, {
    xPage,
  });

export const fetchLiveWorkoutRegister = async (
  data: LiveWorkoutRegisterRequest,
  liveWorkoutId: number,
  xPage: string,
) =>
  await api(
    Methods.post,
    `/auth/register/live-workout/${liveWorkoutId}`,
    data,
    {
      xPage,
    },
  );

export const validatePromoCode = async (
  data: ValidatePromoCodeRequest,
  xPage: string,
): Promise<PromoCode> =>
  await api<PromoCode>(Methods.post, '/validate/promo-code', data, {
    xPage,
  });

export const validateEmail = async (data: ValidateEmailRequest): Promise<any> =>
  await api<any>(Methods.post, '/validate/email', data);

export const fetchEventRegister = async (
  data: LiveWorkoutRegisterRequest,
  eventId: number,
  xPage: string,
) =>
  await api(Methods.post, `/auth/register/event/${eventId}`, data, { xPage });

export const logoutUser = async () => api(Methods.post, '/logout');

import styled from 'styled-components';

interface Props {
  display?: string;
}

/**
 * Unstyled button should be used when semantically an element should behave like
 * a button but we do not want the styles of the button to come with
 */
export default styled.button<Props>`
  display: ${(p) => p.display || 'block'};
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  font: inherit;
  color: inherit;

  &:focus {
    outline-color: transparent;
    outline-style: none;
  }
`;

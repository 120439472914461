import React from 'react';
import { IconNames } from '../../Icons';
import styled from 'styled-components';
import NavigationItem from './NavigationItem';
import { FOR_CREATORS, ABOUT_US, LOGIN } from '../../../constants/routes';
import {
  FetchUserProfileResponse,
  SubscriptionResponse,
} from '@solin-fitness/types';
import { HeaderProps, HeaderVariant } from '../Header';
import ProfileDropdown from './NavigationProfileDropdown';

const STYLES = {
  dark: {
    '--spacer-color': 'var(--color-white)',
  },
  light: {
    '--spacer-color': 'var(--color-gray-700)',
  },
};

type Props = {
  subscriptions?: SubscriptionResponse[];
  profile?: FetchUserProfileResponse;
  signedIn: boolean;
} & HeaderProps;

/**
 * Signed OUT state of navigation menu
 * Includes our marketing page links and options to sign up or sign in
 */
const DefaultNavMenu = ({ variant }: { variant: HeaderVariant }) => (
  <NavMenu>
    <NavigationItem
      href={FOR_CREATORS}
      variant={variant}
      value="For Creators"
    />
    <NavigationItem href={ABOUT_US} variant={variant} value="About Us" />
    <NavigationItem
      href={LOGIN}
      variant={variant}
      value="Sign In"
      iconRight
      id={IconNames.user}
    />
  </NavMenu>
);

/**
 * Signed IN state of navigation menu
 * Includes user's subscriptions and profile
 */
const SignedInNavMenu = ({
  variant,
  subscriptions,
  profile,
}: {
  variant: HeaderVariant;
  subscriptions?: SubscriptionResponse[];
  profile: FetchUserProfileResponse;
}) => {
  const userInitials = `${profile.firstName?.[0]}${profile.lastName?.[0]}`;
  const spacerStyles = STYLES[variant];
  return (
    <NavMenu>
      {subscriptions?.map((subscription) => (
        <NavigationItem
          key={subscription.pageUrl}
          href={{
            pathname: '/[creatorPage]',
            query: {
              creatorPage: subscription.pageUrl,
            },
          }}
          variant={variant}
          value={subscription.pageName}
          avatar={subscription.creator.profile.profilePictureUrlPublicId}
        />
      ))}
      {!!subscriptions?.length ? <Spacer style={spacerStyles} /> : null}
      <ProfileDropdown
        variant={variant}
        value={profile.firstName}
        avatar={profile.profilePictureUrlPublicId}
        initials={userInitials}
      />
    </NavMenu>
  );
};

const NavigationMenu = ({ variant, signedIn, subscriptions, profile }: Props) =>
  signedIn && profile ? (
    <SignedInNavMenu
      variant={variant}
      subscriptions={subscriptions}
      profile={profile}
    />
  ) : (
    <DefaultNavMenu variant={variant} />
  );

const NavMenu = styled.nav`
  display: flex;
  gap: 42px;
`;

const Spacer = styled.div`
  width: 2px;
  margin: 0 -16px;
  background: var(--spacer-color);
`;

export default NavigationMenu;
